<template>
  <div>
    <div class="w-100 d-flex justify-content-between align-items-center">
      <ion-button
        v-if="get(user, 'is_email_verified', true)"
        class="new-starter-btn"
        :disabled="isStatusLoading"
        @click.prevent="openStarterForm"
      >
        <ChLoading size="sm" v-if="isLoading" class="spinner" />
        <span v-else>Submit New Starter</span>
      </ion-button>
    </div>

    <div v-if="isLoading || isStatusLoading" class="d-flex justify-content-center my-4">
      <ChLoading size="sm" class="spinner" />
    </div>

    <ion-card
      v-else-if="!isEmpty(rolechatStarter)"
      class="mx-auto starter-card"
      :class="isMobSmallScreen ? 'w-100' : 'w-75'"
    >
      <ion-card-content class="text-black h-100">
        <div class="starter-content">
          <h4 class="mb-2"><strong>STARTER</strong></h4>
          <p class="mb-2">{{ rolechatStarter.description }}</p>

          <!-- Preferred characters -->
          <div v-if="get(rolechatStarter.pref_characters, 'length')" class="row mx-0 mb-2">
            <router-link
              v-for="char in rolechatStarter.pref_characters"
              :key="char.id"
              class="routerlink clickable-item-hov"
              :to="{
                name: 'character-profile-new',
                params: { slug: char.slug },
              }"
              target="_blank"
              :title="get(char, 'info.name', '')"
            >
              <img
                loading="lazy"
                v-image
                class="mr-2 character-img"
                :src="resizeUpload(char.info.cropProfilePicture, '80x80')"
              />
            </router-link>
          </div>

          <hr class="my-3" />

          <h4
            class="mb-2"
            :class="{
              'text-color-username':
                usernameColor(rolechatStarter.rolechat_profile.user) &&
                !usernameColor(rolechatStarter.rolechat_profile.user).startsWith('#'),
              'bold-username': usernameColor(rolechatStarter.rolechat_profile.user),
            }"
            :style="
              !usernameColor(rolechatStarter.rolechat_profile.user)
                ? ''
                : usernameColor(rolechatStarter.rolechat_profile.user).startsWith('#')
                ? `color: ${usernameColor(rolechatStarter.rolechat_profile.user)} !important`
                : `background-image: ${usernameColor(rolechatStarter.rolechat_profile.user)} !important`
            "
          >
            <strong>{{ rolechatStarter.rolechat_profile.user.username }}</strong>
          </h4>

          <!-- Profile Preferences Tags -->
          <div class="row mx-0">
            <ion-badge class="px-3 profile-badge mr-2 mb-2"
              >Likes
              {{ rolechatStarter.rolechat_profile.ideal_message_len === 'long' ? 'long' : 'short' }}
              replies
            </ion-badge>
            <ion-badge
              v-for="(favType, index) in rolechatStarter.rolechat_profile.fav_types"
              :key="`${favType}-${index}`"
              class="px-3 profile-badge mr-2 mb-2"
              >Likes {{ favType }}
            </ion-badge>
          </div>

          <h4 class="mb-2"><strong>Looking for:</strong></h4>
          <p class="mb-2">{{ rolechatStarter.rolechat_profile.looking_for }}</p>

          <h4 class="mb-2"><strong>Sample Rolechat Message:</strong></h4>
          <p class="mb-2">
            <i>{{ rolechatStarter.rolechat_profile.sample_text }}</i>
          </p>
        </div>

        <div class="d-flex justify-content-center">
          <div class="d-flex justify-content-between" :class="isMobSmallScreen ? 'w-75' : 'w-50'">
            <ion-button color="danger" class="circle-btn" :disabled="isResponding" @click.prevent="declineStarter">
              <i class="ti-close circle-btn-icon" />
            </ion-button>

            <ion-button color="light" class="circle-btn" @click.prevent="fetchStarter">
              <i class="ti-reload circle-btn-icon" />
            </ion-button>

            <ion-button color="success" class="circle-btn" :disabled="isResponding" @click.prevent="acceptStarter">
              <i class="ti-heart circle-btn-icon" />
            </ion-button>
          </div>
        </div>
      </ion-card-content>
    </ion-card>

    <div v-else class="my-4">
      <div class="mb-2">No Active Rolechat Starters.</div>
      <div><a href="#" class="link" @click.prevent="openStarterForm">CLICK HERE</a> to post one!</div>
    </div>
  </div>

  <rolechat-profile-form-modal
    :is-open="isProfileFormOpen"
    @dismiss-modal="closeProfileForm"
    @save-dismiss="closeSaveProfileForm"
  ></rolechat-profile-form-modal>

  <post-rolechat-starter-modal
    :is-open="isStarterFormOpen"
    @dismiss-modal="closeStarterForm"
  ></post-rolechat-starter-modal>

  <VerificationModal :is-open="verificationModal" @dismiss-modal="closeVerificationModal" />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { IonCard, IonCardHeader, IonCardContent, alertController } from '@ionic/vue';
import {
  acceptReqRolechatStarter,
  declineRolechatStarter,
  getRolechatProfileStatus,
  getRolechatRandStarter,
} from '@/shared/actions/rolechats';
import RolechatProfileFormModal from '@/shared/modals/RolechatProfileFormModal.vue';
import PostRolechatStarterModal from '@/shared/modals/PostRolechatStarterModal.vue';
import { toast } from '@/shared/native';
import { resizeUpload } from '@/shared/utils/upload';
import VerificationModal from '@/shared/modals/VerificationModal.vue';
import { authStore } from '@/shared/pinia-store/auth';

@Options({
  name: 'RolechatDiscover',
  components: {
    IonCard,
    IonCardHeader,
    IonCardContent,
    RolechatProfileFormModal,
    PostRolechatStarterModal,
    VerificationModal,
  },
})
export default class RolechatDiscover extends Vue {
  public isLoading = false;
  public isStatusLoading = false;
  public isResponding = false;
  resizeUpload = resizeUpload;
  public isProfileFormOpen = false;
  public isStarterFormOpen = false;

  get = get;
  isEmpty = isEmpty;

  public rolechatStarter: any = {};

  paging = { next: null, previous: null, count: 0 };
  page = 1;

  public get windowWidth() {
    const { width } = useWindowSize();
    return width.value;
  }

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  public get nextPageExists() {
    return !!this.paging.next;
  }

  public get isMobSmallScreen() {
    return this.windowWidth <= 420;
  }

  public verificationModal = false;

  public openVerificationModal = () => {
    this.verificationModal = true;
  };

  public closeVerificationModal = () => {
    this.verificationModal = false;
  };

  public usernameColor = (user: any) => {
    return user?.customize_profile?.username_color || '';
  };

  public async fetchRCProfileStatus() {
    this.isStatusLoading = true;
    try {
      const resp = await getRolechatProfileStatus();
      if (!get(resp, 'profile_exists', false)) {
        const alert = await alertController.create({
          cssClass: 'welcome-rc-alert',
          header: 'WELCOME TO ROLECHAT',
          message: `Rolechats are fun and let you explore your characters in new ways! Some ground rules:
        <ul>
        <li>Please treat other community members with the upmost respect and be a good partner.</li>
        <li>Being a good partner means listening to what they say, staying within the other person’s boundaries.</li>
        <li>Abuse of these rules will not be tolerated and can lead to immediate bans.</li>
        </ul>`,
          buttons: [
            {
              text: 'Disagree',
              id: 'disagree-button',
              role: 'notok',
              cssClass: 'mx-auto text-danger',
            },
            {
              text: 'Agree',
              id: 'confirm-button',
              role: 'ok',
              cssClass: 'mx-auto',
            },
          ],
          backdropDismiss: false,
        });
        await alert.present();

        const { role } = await alert.onDidDismiss();

        if (role === 'ok') {
          this.openProfileForm();
        } else {
          this.goToActive();
        }
      }
    } catch (_err) {
      const router = useRouter();
      await toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
      router.push({ name: 'home' });
    }
    this.isStatusLoading = false;
  }

  public openProfileForm() {
    this.isProfileFormOpen = true;
  }

  public closeProfileForm() {
    if (this.isProfileFormOpen) {
      this.isProfileFormOpen = false;
      this.$nextTick(() => this.goToActive());
    }
  }

  public closeSaveProfileForm() {
    this.isProfileFormOpen = false;
  }

  public openStarterForm() {
    this.isStarterFormOpen = true;
  }

  public closeStarterForm() {
    this.isStarterFormOpen = false;
  }

  public async fetchStarter() {
    this.isLoading = true;

    try {
      const resp = await getRolechatRandStarter();

      this.rolechatStarter = resp;
    } catch (_err) {}

    this.isLoading = false;
  }

  public async acceptStarter() {
    if (this.isResponding) return;

    this.isResponding = true;

    try {
      await acceptReqRolechatStarter({
        rolechat_starter: this.rolechatStarter.id,
      });
      this.fetchStarter();
      await toast.show(
        `Request sent. Rolechat will start as soon as ${get(
          this.rolechatStarter,
          'rolechat_profile.user.username',
          'the user'
        )} accepts.`,
        'nonative',
        'success'
      );
    } catch (_err) {
      await toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
    }
    this.isResponding = false;
  }

  public async declineStarter() {
    if (this.isResponding) return;

    this.isResponding = true;

    try {
      await declineRolechatStarter(this.rolechatStarter.id!);
      this.fetchStarter();
    } catch (_err) {
      await toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
    }
    this.isResponding = false;
  }

  public goToActive() {
    this.$emit('changeTab', { value: 'active' });
  }

  mounted() {
    if (!get(this.user, 'is_email_verified', true)) {
      this.openVerificationModal();
    } else {
      this.fetchRCProfileStatus();
      this.fetchStarter();
    }
  }
}
</script>

<style scoped lang="sass">
.unread-chat
  font-weight: bold
.spinner
  width: 40px
  height: 40px
.circle-btn
  --border-radius: 100% !important
  border-radius: 100% !important
  width: 50px
  height: 50px
.circle-btn-icon
  width: 25px
  height: 25px
.profile-badge
  border-radius: 40px

.new-starter-btn
  width: 180px
  .spinner
    width: 15px
    height: 15px

.character-img
  width: 50px
  height: 50px
  border-radius: 40px
  border: solid gray 0.1px
  object-fit: cover

.filters-icon
  width: 25px
  height: 25px

.starter-card
  height: calc(100vh - 300px)

.starter-content
  overflow: auto
  height: calc(100% - 50px)
</style>
