<template>
  <div>
    <div v-if="rolechats && rolechats.length">
      <div v-for="room of rolechats" :key="room.id" class="mb-4">
        <router-link :to="{ name: 'rolechat-room', params: { id: room.id } }" class="text-black clickable-item-hov">
          <h4 :class="{ 'unread-chat': room.is_read === false }">{{ room.title }}</h4>
          <div :class="{ 'unread-chat': room.is_read === false }">
            <span v-if="room.latest_msg">{{ truncateText(room.latest_msg, 200) }}</span
            ><i v-else>No messages yet</i>
          </div>
        </router-link>
      </div>

      <p v-if="nextPageExists" class="clickable-item-hov" @click="requestLoadMore">Load More</p>
    </div>
    <div v-else class="my-4">
      <div class="mb-2">No Active Rolechats.</div>
      <div>
        <router-link :to="{ name: 'rolechats', query: { tab: 'discover' } }" @click.prevent="goToDiscover"
          >CLICK HERE</router-link
        >
        to start one!
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Action, Getter } from 's-vuex-class';
import namespace from '@/shared/store/namespace';
import { truncateText } from '@/shared/utils/string';

@Options({
  name: 'ActiveRolechats',
  components: {},
})
export default class ActiveRolechats extends Vue {
  public isLoading = false;

  get = get;
  truncateText = truncateText;

  @Getter('rolechats', { namespace: namespace.RolechatModule })
  public rolechats!: any[];

  @Getter('rolechatsPaging', { namespace: namespace.RolechatModule })
  public rolechatsPaging!: any;

  @Action('getRolechats', { namespace: namespace.RolechatModule })
  public getRolechats!: any;

  @Action('rolechatsNextPage', { namespace: namespace.RolechatModule })
  public rolechatsNextPage!: any;

  public get nextPageExists() {
    return !!this.rolechatsPaging.next;
  }

  public async fetchRolechats() {
    this.isLoading = true;
    await this.getRolechats({ from: 'activechats' });
    this.isLoading = false;
  }

  public async requestLoadMore(ev: CustomEvent) {
    if (!this.rolechatsPaging.next) {
      (ev?.target as any).complete();
    } else {
      await this.rolechatsNextPage();
    }
  }

  public goToDiscover() {
    this.$emit('changeTab', { value: 'discover' });
  }

  mounted() {
    this.fetchRolechats();
  }
}
</script>

<style scoped lang="sass">
.unread-chat
  font-weight: bold
</style>
