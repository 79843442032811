<template>
  <ion-modal :is-open="isOpen" mode="md" :backdrop-dismiss="true" :show-backdrop="true" @didDismiss="dismissModal">
    <modals-layout :data="{ title: modalTitle }" @dismiss-modal="dismissModal">
      <div class="h-100 px-4" style="overflow: auto">
        <form @submit.prevent="onSubmit">
          <p class="mb-3">Starters can be prompts or full on stories or requests.</p>

          <p class="mb-3">They expire in 3 days! Keep an eye on your "Requests" tab for matches.</p>

          <ion-textarea
            v-model="starter.description"
            rows="5"
            class="c-textarea mb-3"
            placeholder="Share a prompt of what you're looking for in your roleplay! Mentions of NSFW or anything mature are not allowed in starters."
          />

          <div class="mb-2">
            <strong>Characters you want to rolechat with (Ideally choose up to 5 characters)</strong>
          </div>
          <div class="mb-3">
            <ion-select
              v-model="starter.pref_characters"
              mode="md"
              placeholder="Select desired characters"
              :multiple="true"
            >
              <ion-select-option v-for="char in userCharacters" :key="char.id" :value="char.id">{{
                get(char, 'info.name')
              }}</ion-select-option>
            </ion-select>
          </div>

          <div class="w-100 d-flex justify-content-center">
            <ion-button class="submit-btn" :disabled="isSubmitting || !isFormComplete" type="submit">
              <ChLoading size="sm" v-if="isSubmitting" class="spinner" />
              <span v-else>Submit</span>
            </ion-button>
          </div>
        </form>
      </div>
    </modals-layout>
  </ion-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { Getter } from 's-vuex-class';
import { createRolechatStarter } from '@/shared/actions/rolechats';
import { getUserCharacters } from '@/shared/actions/characters';
import namespace from '@/shared/store/namespace';
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';

@Options({
  name: 'PostRolechatStarterModal',
})
export default class PostRolechatStarterModal extends Vue {
  @Prop({ default: () => 'Add A New Starter', required: false }) modalTitle!: string;
  @Prop({ default: false }) isOpen!: boolean;

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  get = get;

  isSubmitting = false;
  areCharsLoading = false;
  starter = {
    description: '',
    pref_characters: [],
  };

  userCharacters = [];

  public get isFormComplete() {
    return !!this.starter.description && !isEmpty(this.starter.pref_characters);
  }

  public get isFormLoading() {
    return this.isSubmitting || this.areCharsLoading;
  }

  public dismissModal() {
    this.$emit('dismissModal');
    this.starter.description = '';
    this.starter.pref_characters = [];
    this.isSubmitting = false;
  }

  public async onSubmit() {
    if (!this.isFormComplete) return;

    this.isSubmitting = true;

    try {
      await createRolechatStarter(this.starter);
      await toast.show('Starter posted successfully. Keep an eye on requests for matches.', 'nonative', 'success');
    } catch (_err) {
      await toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
    }

    this.dismissModal();
    this.isSubmitting = false;
  }

  public async fetchUserCharacters() {
    this.areCharsLoading = true;

    this.userCharacters = await getUserCharacters(this.user.id, null);

    this.areCharsLoading = false;
  }

  mounted() {
    this.fetchUserCharacters();
  }
}
</script>

<style lang="sass" scoped>
.submit-btn
  width: 180px
  .spinner
    width: 15px
    height: 15px

ion-modal
  @media(max-width: 599px) and (max-height: 767px)
    --height: 85%
    --width: 85%
</style>
